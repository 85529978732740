// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modBody .parentModalContentJsx {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modBody .parentModalContentJsx p {
  display: inline-block;
  font-size: large;
  font-weight: bold;
}
.modBody .parentModalContentJsx .success {
  color: green;
}
.modBody .parentModalContentJsx .failure {
  color: red;
}
.modBody .btnContent {
  display: flex;
  align-items: center;
}
.modBody .btnContent button {
  background-color: #0b5ed7;
  border: none;
}
.modBody .btnContent button:hover {
  background-color: #05357e;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/SharedComponent/ModalPopUp/ModalPopUp.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AAAR;AACQ;EACI,qBAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AACQ;EACI,YAAA;AACZ;AACQ;EACI,UAAA;AACZ;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AAEM;EACE,yBAAA;EACA,YAAA;AAAR;AACQ;EACI,yBAAA;EACA,YAAA;AACZ","sourcesContent":[".modBody{\n    .parentModalContentJsx{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        p{\n            display: inline-block;\n            font-size: large;\n            font-weight: bold;\n        }\n        .success{\n            color: green;\n        }\n        .failure{\n            color: red;\n        }\n    }\n    .btnContent{\n        display: flex;\n        align-items: center;\n       \n      button{\n        background-color: #0b5ed7;\n        border: none;\n        &:hover{\n            background-color:#05357e;\n            border: none;\n        }\n      }  \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
