// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer {
  padding: 20px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.formContainer Button {
  width: 100%;
  padding: 10px;
  font-weight: 600;
}
.formContainer Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.formContainer Form input {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Login/Login.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EAgBA,qDAAA;AAdJ;AADI;EACI,WAAA;EACA,aAAA;EACA,gBAAA;AAGR;AADI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;AAGR;AAFQ;EACI,aAAA;AAIZ","sourcesContent":[".formContainer {\n    padding: 20px;\n    background: #fff;\n    Button {\n        width: 100%;\n        padding: 10px;\n        font-weight: 600;\n    }\n    Form {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        gap: 20px;\n        padding: 20px;\n        input {\n            padding: 10px;\n        }\n    }\n    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
