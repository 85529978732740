// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordianTableList {
  margin-bottom: 30px;
}
.accordianTableList .accordion-item {
  border: 2px solid;
  border-color: darkgray;
}
.accordianTableList .accordion-item .accordion-header button {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Components/AccordianTable/AccordianTable.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAAI;EACI,iBAAA;EACA,sBAAA;AAER;AAEY;EACE,gBAAA;AAAd","sourcesContent":[".accordianTableList{\n    margin-bottom: 30px;\n    .accordion-item{\n        border: 2px solid;\n        border-color: darkgray;\n\n        .accordion-header{\n          \n            button{\n              font-weight: 500;\n                \n            }\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
