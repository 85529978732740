// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-notfound h1 {
  font-size: 7rem;
}
.page-notfound p {
  font-weight: bolder;
  color: lightslategrey;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagenotFound/PagenotFound.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AACE;EACE,mBAAA;EACA,qBAAA;AACJ","sourcesContent":[".page-notfound {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  h1 {\n    font-size: 7rem;\n  }\n  p {\n    font-weight: bolder;\n    color: lightslategrey;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
