// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Assets/images/png/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topBorder {
  height: 20px;
  width: 100%;
  background-color: bisque;
}

.appWrapper {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center/cover;
  height: 100vh;
}
.appWrapper:has(.page-notfound) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.appWrapper:not(:has(.loginform, .page-notfound)) {
  background-image: none;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,wBAAA;AADF;;AAGA;EACE,gEAAA;EACA,aAAA;AAAF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACE;EACE,sBAAA;AACJ","sourcesContent":["\n\n.topBorder{\n  height: 20px;\n  width: 100%;\n  background-color: bisque;\n}\n.appWrapper {\n  background: url('./Assets/images/png/background.jpg') center/cover;\n  height: 100vh;\n  &:has(.page-notfound){\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  &:not(:has(.loginform, .page-notfound)){\n    background-image: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
